



































































































































import { View } from '@/app_code/services/meta/view'
import { Component, Mixins } from 'vue-property-decorator'
import ContactForm from '../components/ContactForm.vue'
import Topic from '../components/Topic.vue'

@Component({
  components: {
    Topic,
    ContactForm
  }
})
export default class Contact extends Mixins(View) {}
