













































import { API, ServiceManager } from '@/app_code/services'
import ServiceType from '@/app_code/services/ServiceTypes'
import { Mixins, Vue, Component } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class ContactForm extends Mixins(Vue) {
  private name = ''
  private email = ''
  private phone = ''
  private message = ''

  private isSent = false

  private loading = false

  mounted () {
    if ('grecaptcha' in window) {
      const win = window as any

      win.grecaptcha.ready(() => {
        win.grecaptcha.render('contact-recaptcha', {
          sitekey: '6Le2wN8UAAAAABjO7OBCp3uWXYv5BQxF-Bf4ULqr'
        })
      })
    }
  }

  async handleSubmit () {
    const api = ServiceManager.Require<API>(ServiceType.API)

    const win = window as any

    const recaptchaResponse = win.grecaptcha.getResponse()

    if (!recaptchaResponse) {
      alert('Please tick "I\'m not a robot"')
      return
    }

    this.loading = true

    const result = await api.SiteAPI.SubmitContactForm({
      Name: this.name,
      Email: this.email,
      Phone: this.phone,
      Message: this.message,
      ReCaptchaResponse: recaptchaResponse
    })

    this.isSent = true
    this.loading = false
  }
}
